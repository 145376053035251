import {Controller} from "@hotwired/stimulus";

const MAX_ADDRESS_LENGTH = 50;

export default class extends Controller {
  static targets = ["container", "address"];

  connect() {
    this.required();
  }

  required() {
    const isRequired = !this.containerTarget.classList.contains("hidden");

    const elements = this.containerTarget.querySelectorAll('[data-required="true"]');
    for (const element of elements) {
      element.required = isRequired;
    }
  }

  showError(element, message) {
    const error = element.querySelector(".error");

    if (!error.classList.contains("hidden")) return;

    error.classList.remove("hidden");
    error.innerHTML = message;
  }

  removeError(element) {
    const error = element.querySelector(".error");

    error.classList.add("hidden");
    error.innerHTML = "";
  }

  isAddressValid() {
    const value = this.addressTarget.querySelector("input").value;

    if (value.length > MAX_ADDRESS_LENGTH) {
      this.showError(
        this.addressTarget,
        `Street and house number can't be longer than ${MAX_ADDRESS_LENGTH} characters`,
      );
      return false;
    } else {
      this.removeError(this.addressTarget);
      return true;
    }
  }

  submit(e) {
    if (this.isAddressValid()) return;

    e.preventDefault();
  }
}
