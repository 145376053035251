import {Controller} from "@hotwired/stimulus";
import {getCookie} from "../utils";

// Connects to data-controller="analytics"
export default class extends Controller {
  static values = {
    payload: Object,
  };

  addToCart() {
    if (typeof fbq === "function") {
      fbq("track", "AddToCart", this.facebookAddToCart(this.payloadValue));
    }

    if (window.dataLayer) {
      window.dataLayer.push(this.gtmAddToCart(this.payloadValue));
    }

    if (window._learnq) {
      _learnq.push(["track", "Added to Cart", this.klaviyoAddToCart(this.payloadValue)]);
    }
  }

  identifyUser(e) {
    if (window._learnq) {
      _learnq.push(["identify", {$email: e.target.value, $id: getCookie("_storefront_browser_id")}]);
    }
  }

  facebookAddToCart(generic) {
    return {
      content_type: "product",
      contents: [{id: generic.sku, quantity: generic.quantity}],
      content_name: generic.name,
      value: generic.value,
      currency: generic.currency,
    };
  }

  klaviyoAddToCart(generic) {
    return {
      $id: getCookie("_storefront_browser_id"),
      ProductName: generic.name,
      ProductID: generic.sku,
      Quantity: generic.quantity,
      Price: generic.value,
      Currency: generic.currency,
    };
  }

  gtmAddToCart(generic) {
    return {
      event: "add_to_cart",
      value: generic.value,
      currency: generic.currency,
      items: [
        {
          item_id: generic.sku,
          item_name: generic.name,
        },
      ],
    };
  }
}
